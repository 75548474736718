<template>
  <div>
    <SpinnerLoader v-if="salesPlanExecutionsTVNetworkStatus !== 'success'" :loading="salesPlanExecutionsTVNetworkStatus">ddd</SpinnerLoader>
    <template
      v-else-if="salesPlanExecutionsTVNetworkStatus === 'success' && salesPlanExecutionsTVNetwork.data && salesPlanExecutionsTVNetwork.data[selectedTab]"
    >
      <div class="table-responsive mb-0 sales-plan-executions">
        <table class="table table-sm small table-hover table-bordered ws-nowrap sticky-headers sticky-footer">
          <thead>
            <tr>
              <th rowspan="2">
                <div class="resizable period">{{ $t('table.period') }}</div>
              </th>
              <th colspan="2">wGRP {{ $t('table.open') }}</th>
              <th colspan="2">wGRP {{ $t('reports.soldOut') }}</th>
              <th colspan="2">CPP</th>
              <th colspan="2">{{ $t('table.budget') }}</th>
            </tr>
            <tr>
              <th class="text-right">{{ $t('table.plan') }}</th>
              <th class="text-right">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>

              <th class="text-right">{{ $t('reports.cp') }}</th>
              <th class="text-right">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>

              <th class="text-right">{{ $t('reports.cp') }}</th>
              <th class="text-right">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>

              <th class="text-right">{{ $t('reports.cp') }}</th>
              <th class="text-right">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in salesPlanExecutionsTVNetwork.data[selectedTab].items">
              <tr v-if="true" :key="index + '_3'">
                <th name="period">{{ item.month ? months[+item.month - 1] : '' }}</th>

                <th class="text-right">{{ item.plan_wgrp_open | toFixedAndSpace }}</th>
                <th class="text-right">{{ item.plan_fact_wgrp_open | toFixedAndSpace }}</th>

                <th class="text-right">{{ item.plan_wgrp_sold | toFixedAndSpace }}</th>
                <th class="text-right">{{ item.plan_fact_wgrp_sold | toFixedAndSpace }}</th>

                <th class="text-right">{{ item.plan_cpp | toFixedAndSpace }}</th>
                <th class="text-right">{{ item.plan_fact_cpp | toFixedAndSpace }}</th>

                <th class="text-right">{{ item.plan_budget | toFixedAndSpace }}</th>
                <th class="text-right">{{ item.plan_fact_budget | toFixedAndSpace }}</th>
              </tr>
            </template>
          </tbody>

          <tfoot v-if="salesPlanExecutionsTVNetwork.data[selectedTab].totals" class="font-weight-bold">
            <tr>
              <th name="period" class="text-uppercase">{{ $t('booking.total') }}</th>

              <th class="text-right">{{ salesPlanExecutionsTVNetwork.data[selectedTab].totals.plan_wgrp_open | toFixedAndSpace }}</th>
              <th class="text-right">{{ salesPlanExecutionsTVNetwork.data[selectedTab].totals.plan_fact_wgrp_open | toFixedAndSpace }}</th>

              <th class="text-right">{{ salesPlanExecutionsTVNetwork.data[selectedTab].totals.plan_wgrp_sold | toFixedAndSpace }}</th>
              <th class="text-right">{{ salesPlanExecutionsTVNetwork.data[selectedTab].totals.plan_fact_wgrp_sold | toFixedAndSpace }}</th>

              <th class="text-right">{{ salesPlanExecutionsTVNetwork.data[selectedTab].totals.plan_cpp | toFixedAndSpace }}</th>
              <th class="text-right">{{ salesPlanExecutionsTVNetwork.data[selectedTab].totals.plan_fact_cpp | toFixedAndSpace }}</th>

              <th class="text-right">{{ salesPlanExecutionsTVNetwork.data[selectedTab].totals.plan_budget | toFixedAndSpace }}</th>
              <th class="text-right">{{ salesPlanExecutionsTVNetwork.data[selectedTab].totals.plan_fact_budget | toFixedAndSpace }}</th>
            </tr>
            <tr v-if="tabsCount > 1">
              <th colspan="7" class="text-uppercase">{{ $t('subChannels.totalAllSubChannels') }}</th>
              <th class="text-right">{{ salesPlanExecutionsTVNetwork.totals.plan_budget | toFixedAndSpace }}</th>
              <th class="text-right">{{ salesPlanExecutionsTVNetwork.totals.plan_fact_budget | toFixedAndSpace }}</th>
            </tr>
            <tr v-if="tabsCount > 1">
              <td colspan="9">
                <div class="d-flex px-3 py-2 overflow-auto">
                  <b-button
                    v-for="(item, name) in salesPlanExecutionsTVNetwork.data"
                    :key="name"
                    size="sm"
                    :variant="selectedTab === name ? 'primary' : 'link'"
                    @click="changeActiveTab(name)"
                  >
                    {{ name }}
                  </b-button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
import SpinnerLoader from '@/components/SpinnerLoader.vue';

export default {
  name: 'SalesPlanExecutionsTable',
  components: { SpinnerLoader },
  filters: { toFixedAndSpace },
  props: {},
  data() {
    return {
      selectedTab: '',
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      salesPlanExecutionsTVNetwork: 'getReportSalesPlanExecutionsTVNetwork',
      salesPlanExecutionsTVNetworkStatus: 'getReportSalesPlanExecutionsTVNetworkStatus',
    }),
    tabsCount() {
      if (this.salesPlanExecutionsTVNetworkStatus === 'success' && this.salesPlanExecutionsTVNetwork) {
        return Object.keys(this.salesPlanExecutionsTVNetwork.data).length;
      } else return 1;
    },
    months() {
      return [
        this.$i18n.t('month.jan'),
        this.$i18n.t('month.feb'),
        this.$i18n.t('month.mar'),
        this.$i18n.t('month.apr'),
        this.$i18n.t('month.may'),
        this.$i18n.t('month.jun'),
        this.$i18n.t('month.jul'),
        this.$i18n.t('month.aug'),
        this.$i18n.t('month.sep'),
        this.$i18n.t('month.oct'),
        this.$i18n.t('month.nov'),
        this.$i18n.t('month.dec'),
      ];
    },
  },
  watch: {
    salesPlanExecutionsTVNetwork() {
      if (this.salesPlanExecutionsTVNetworkStatus === 'success' && this.salesPlanExecutionsTVNetwork.data) {
        this.selectedTab = Object.keys(this.salesPlanExecutionsTVNetwork.data)[0];
      }
    },
    selectedTab() {
      // scroll to the top of wrapper on tab change
      const tableWrapper = document.querySelector('div.sales-plan-executions');
      if (tableWrapper) tableWrapper.scrollTop = 0;
    },
  },
  destroyed() {
    this.$store.commit('clearReportSalesPlanExecutionsTVNetwork');
  },
  methods: {
    changeActiveTab(name) {
      this.selectedTab = name;
    },
  },
};
</script>
<style lang="sass">
.reports-page div.table-responsive.sales-plan-executions
  height: calc(100vh - 155px)

.reports-page .sales-plan-executions table.table
  thead th[name="prime"]
    min-width: 75px

  div.resizable.period
    min-width: 50px

  td[name="period"]
    max-width: 50px

@media (max-width: 1515px)
  .reports-page div.table-responsive.sales-plan-executions
    height: calc(100vh - 245px)
</style>
